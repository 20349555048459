<template>
    <div class="button-close">
        <button class="button is-black is-fullwidth"
                v-on:click="modalOpen = true">
            <strong>Close</strong>
        </button>
        <b-modal v-bind:active.sync="modalOpen">

        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'ButtonClose',
        props: {
            orderId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                modalOpen: false
            }
        },
        computed: {
            order() {
                let orders = this.$store.state.shop.orders
                if (orders && Array.isArray(orders)) {
                    let target = orders.find(order => order._id === this.orderId)
                    if (target) {
                        return target
                    }
                }
                return null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .button-close {
        width: 100%;
    }
</style>
